:root {
  --color-primary: #4685c5;
  --color-light-primary: #90b6dc;
  --color-ultra-light-primary: #edf3f9;
  --color-darken-primary: #315d8a;
  --color-box-border: #C3C3C3;
}

html {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-content {
  padding: 70px 20px;
  height: 100vh;
  min-width: 1012px;
  background-color: rgba(0, 0, 0, 0.04);
}
.page-content.medium {
  min-width: unset;
}
.content-container {
  margin-top: 30px;
}

.box {
  margin: 0;
  border: solid 1px var(--color-box-border);
  border-radius: 0.3rem;
  box-shadow: 0 0 5px rgba(0,0,0,0.08);
}

/* styles for grids */
.my-bs-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-bs-col {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}


/* styles for Kendo DropDownList */
.k-picker-md .k-input-button {
  min-width: unset;
}

/* styles for kendo combobox */
.k-combobox > button {
  border-radius: 0 0.3rem 0.3rem 0;
}

.k-combobox > button > .k-button-icon {
  /*font-size: 18px;*/
}

/* styles for kendo textarea */
.k-textarea > textarea {
  resize: none;
  height: 100px;
}
/* styles for scheduler */
.k-form.k-form-horizontal > .k-dialog-buttongroup {
  border: unset;
}

/* styles for kendo CheckBox */
.k-checkbox-label {
  padding-left: 3px;
  font-size: 14px;
}

/* styles for kendo multiselect */
.k-selection-multiple > .k-chip-md {
  /*font-size: 18px;*/
  line-height: 1;
}

/* style the bootstrap div row */
div.row.inner-row {
  --bs-gutter-x: 0;
}

/* style the filter cells in a data grid */
.k-filter-row > th {
  padding: 2px;
}

div.k-filtercell > span {
  height: 30px;
}

div.k-filtercell > .k-button-icon {
  height: 28px;
  width: 28px;
  min-width: unset;
  margin: 0 2px
}

.k-filtercell > span > .k-button {
  width: 30px;
  margin: 0 2px;
}

.k-filtercell .k-input {
  height: 30px;
  padding: 2px;
}

.k-filtercell .k-picker {
  height: 30px;
}

.k-dropdown-operator.k-picker-md .k-input-button {
  width: 30px;
}

/* styles for switch */
.followup-switch {
  width: 60px !important;
}

.support-dashboard-switch {
  width: 100px !important;
}

/* styles for Stepper */
.my-stepper .k-progressbar {
  height: 4px;
  top: 18px;
}

.my-step-icon {
  width: 40px;
  height: 40px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-clip: padding-box;
  box-sizing: border-box;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #ccc;
  /*font-size: 24px;*/
}

.k-step:not(.k-step-done):not(.k-step-current) .custom-step {
  border: 2px solid #ccc;
}

.k-step-link .my-step-label {
  color: #ccc;
  /*font-size: 18px;*/
  font-weight: 500;
}

.my-date-range-picker .k-daterangepicker-wrap {
  width: 100%;
}

.k-daterangepicker-wrap {
  width: 100%;
}

.k-input-spinner .k-spinner-increase {
  margin: 0;
  border-radius: 0 0.3rem 0 0;
}

.k-input-spinner .k-spinner-decrease {
  margin: 0;
  border-radius: 0 0 0.3rem 0;
}

/* styles for input element */
.k-input {
  background-color: #f5f5f5;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.kendo-price-input {
  text-align: right;
}

.month-input {
  text-align: right;
}

/* styles for dashboard button group only */
.dashboard-button-group button:not(.k-selected) {
  background-color: white;
}

/* styles for notification card */
.notification-card * {
  cursor: pointer;
}

/* styles for expandable panel */
.k-expanded > .k-expander-header {
  background-color: var(--color-primary);
}
.k-expanded > .k-expander-header > .k-expander-title {
  color: white;
}
.k-expanded .k-expander-indicator span {
  color: white;
  font-weight: 500;
}

/* styles for tabs in patient module */
.patient-reviewer-tab .k-animation-container {
  width: 100%;
}
.patient-report-tab .k-animation-container {
  width: 100%;
}
.patient-invoice-tab .k-animation-container {
  width: 100%;
}

.pdf-uploader-review {
  height: 100%;
}

/* styles for SentencePicker in Patient note */
.sentence-picker .k-animation-container {
  width: 100%;
}

/*patient-billing-tab*/
.patient-billing-tab .k-animation-container {
  width: 100%;
}

/* styles for main page */
@font-face {
  font-family: 'Myriad';
  src: local('Myriad'), url("./fonts/Myriad/Myriad_Condensed_Regular.otf") format('opentype');
}

/* styles for notification center */
.k-notification-group {
  z-index: 100000;
}
.notification-center-item {
  opacity: 0.7;
}
.notification-center-item .k-icon {
  font-size: 18px;
}
.notification-center-item .k-notification-content {
  font-size: 18px;
}
.notification-center-item .k-notification-wrap {
  align-items: center;
}

/* styles for customized search input */
div.search-input-container {
  border: solid 1px rgba(0, 0, 0, .16);
  box-shadow: unset;
}
div.search-input-container:focus {
  border-color: rgba(0, 0, 0, .16);
  box-shadow: 0 0 0 2px rgb(0 0 0 / 8%)
}
div.search-input-container:focus-within {
  border-color: rgba(0, 0, 0, .16);
  box-shadow: 0 0 0 2px rgb(0 0 0 / 8%)
}
div.search-input-container:hover {
  border-color: rgba(0, 0, 0, .16);
}
div.search-input-container:focus-visible {
  outline: unset;
}
input.search-input {
  width: calc(100% - 25px);
  border: unset;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: unset;
  background-image: unset;
  box-shadow: unset !important;
}

/* styles for customized password input */
div.password-input-container {
  border: solid 1px rgba(0, 0, 0, .16);
  box-shadow: unset;
}
div.password-input-container:focus {
  border-color: rgba(0, 0, 0, .16);
  box-shadow: 0 0 0 2px rgb(0 0 0 / 8%)
}
div.password-input-container:focus-within {
  border-color: rgba(0, 0, 0, .16);
  box-shadow: 0 0 0 2px rgb(0 0 0 / 8%)
}
div.password-input-container:hover {
  border-color: rgba(0, 0, 0, .16);
}
div.password-input-container:focus-visible {
  outline: unset;
}
input.password-input {
  width: calc(100% - 25px);
  border: unset;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: unset;
  background-image: unset;
  box-shadow: unset !important;
}
div.password-input-container span.k-floating-label-container label.k-label {
  top: 4px;
  left: 8px;
}
div.password-input-container.filled span.k-floating-label-container label.k-label {
  top: -21px;
  left: 3px;
}
div.password-input-container span.k-floating-label-container:focus label.k-label {
  top: -21px;
  left: 3px;
}
div.password-input-container span.k-floating-label-container:focus-within label.k-label {
  top: -21px;
  left: 3px;
}

/* styles for ScheduledBy popup */
.scheduledby-popup {
  padding: 10px
}
.scheduledby-popup > div > ul > li > .k-treeview-mid > .k-treeview-leaf {
  font-weight: 500;
  color: white;
  background-color: var(--color-primary);
  margin-top: 2px;
}

/* styles for availability calendar */
.k-calendar-tr > div {
  width: fit-content;
  display: contents;
}
.professional-availabilities-box {
  width: 100%;
}

/* stuyles for UserEditForm clinic item */
span.user-clinic-item {
  width: fit-content;
  margin-right: 5px;
}
span.user-room-select-checkbox {
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

/* styles for email event display */
nav.email-stepper span.k-step-label {
  max-width: unset;
}
nav.email-stepper span.k-step-label span.k-step-text {
  max-width: unset;
}
.email-list-popup .k-child-animation-container {
  border-radius: 0.3rem;
}

/* styles for sentence management module */
.sentence-rhs-tab-content .k-animation-container {
  width: 100% !important;
}
.sentence-sortable .draggable-item {
  padding: 0 5px 5px;
}

/* styles for availability scheduler */
.availability-scheduler {
  height: 100% !important;
  border-radius: 0.3rem;
  border-width: 0;
}
.availability-scheduler * {
}
.availability-scheduler .k-scheduler-toolbar {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.availability-service-checkbox span{
  display: flex;
  align-items: flex-start;
}
.radio-button-td-disabled {
  text-decoration: line-through;
}

/* styles for initialization */
.init-clinic-count-textbox input.k-input-inner {
  text-align: center;
}

/* styles for document management module */
.doc-management-tab-content {
  height: calc(100vh - 155px);
  overflow-y: auto;
}
.document-edit-pdf-preview {
  padding: 5px;
  border: solid 1px #C3C3C3;
  border-radius: 0.3rem;
  box-shadow: 0 0 5px rgba(0,0,0,0.08);
  background-color: rgba(0,0,0,0.08);
  margin-right: 10px;
  min-height: 480px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.value-set-edit-pdf-preview {
  padding: 5px;
  border: solid 1px #C3C3C3;
  border-radius: 0.3rem;
  box-shadow: 0 0 5px rgba(0,0,0,0.08);
  background-color: rgba(0,0,0,0.08);
  margin-right: 10px;
  min-height: calc(95vh - 100px);
  max-height: calc(95vh - 80px);
  overflow-x: auto;
  overflow-y: auto;
}

.value-set-layout {
  height: calc(95vh - 80px) !important;
  overflow-y: auto
}

.doc-upload-signature {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: unset;
}
.doc-upload-signature .k-signature-canvas {
  width: calc(100% - 30px);
  height: 240px;
}
.doc-upload-signature-placeholder {
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-left: solid 1px lightgray;
  border-right: solid 1px lightgray;
  border-bottom: solid 1px lightgray;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

/* styles for all DocumentCard using pdfme to preview a PDF */
.document-card-pdfme-viewer {
  height: 500px;
  width: 218px;
  overflow: hidden;
}
.document-card-pdfme-viewer > div {
  background-color: white !important;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.document-card-pdfme-viewer > div > div > div:nth-child(1) {
  display: none;
}
.document-card-pdfme-viewer > div > div > div:nth-child(2) {
  overflow-y: hidden !important;
}

/* styles for pdfme Designer */
div#pdfme-designer > div > div > div:nth-child(2) > div > div > div:last-child {
   display: none;
 }
div#pdfme-designer > div > div > div:nth-child(2) > div > div > div > div > div:last-child {
  height: 600px !important;
}
div#pdfme-designer > div > div > div:nth-child(2) > div > div > div > div > div > div:last-child {
  display: none !important;
}
div.ant-select-dropdown {
  z-index: 10015;
}

.quantity-textbox input.k-input-inner {
  text-align: center;
}
.price-textbox input.k-input-inner {
  text-align: right;
}

div.dialog-dropdown-list {
  z-index: 10105 !important;
}

.horizontal-checkbox {
  width: unset;
}